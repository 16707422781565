<template>
  <div class=wrapper>
    <van-sticky type="primary">
      <head-bar
        title="同期活动"
        @onClickLeft="$router.back(-1)"
        :right="false"
      />
    </van-sticky>
    <div class="content">
        <!-- @click="$router.push('/activity/' + $store.state.miceInfo.miceLink + '/' + 1)" -->
        
            <div class="wrapper-card">
                <a href="https://forms.ebdan.net/ls/PFdQK2wS?bt=yxy">
                    <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306101747354299225.jpeg" alt="">
                </a>
            </div>
        <!-- <div class="wrapper-card" @click="$router.push('/activity/' + $store.state.miceInfo.miceLink + '/' + 1)">
            内容2
        </div> -->
    </div>
  </div>
</template>

<script>
import headBar from "@/components/headBar/headBar";
export default {
  name: "",
  components: {
    headBar
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.wrapper {
    height: 100vh;
    background-image: url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306062004234495727.jpg);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .wrapper-card {
            border: 0.08rem solid #fff;
            padding: 0.13333rem;
            width: 85%;
            height: 180px;
            background: #192a84;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
